.header-title-about{
    display: flex;
    font-size: 60px;
    padding: 95px;
    margin-top: 133px;
}
@media screen and (max-width: 767px){
.header-title-about{
    display: flex;
    font-size: -webkit-xxx-large;
    padding-left: 35px;
    padding-top: 0px;
}
}