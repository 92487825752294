.view-more-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 170px;
    background-color: #f0f0f0; /* Regular gray background */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Regular shadow */
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  }
  
  .view-more-card:hover {
    background-color: #f0f0f0; /* Keep background gray on hover */
    box-shadow: 0 4px 10px rgba(255, 59, 59, 0.6); /* Red shadow on hover */
  }
  
  .arrow,
  .view-more-text {
    color: #333; /* Gray text in regular state */
  }
  
  .view-more-card:hover .arrow,
  .view-more-card:hover .view-more-text {
    color: #ff3b3b; /* Red text on hover */
  }
  
  /* Responsive Design for Mobile View */
  @media (max-width: 600px) {
    .view-more-card {
      height: 80px;
    }
  
    .arrow {
      font-size: 24px;
    }
  
    .view-more-text {
      font-size: 12px;
    }
  }
  