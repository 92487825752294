.related-cards {
    margin-top: 20px;
  }
  
  .related-card {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    transition: transform 0.2s ease;
  }
  
  .related-card:hover {
    transform: scale(1.05);
  }
  
  .related-card img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .related-card h4 {
    margin-top: 10px;
    font-size: 1.2rem;
  }
  
  .related-card p {
    font-size: 0.9rem;
    color: #666;
  }
  
  .related-card a {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .related-card a:hover {
    text-decoration: underline;
  }
  .section-padding{
    margin: 10px;
    padding: 10px;
  }
.detail-card{
  display: flex;
  width: 100%;
}
.detail-card-left{
  display: flex;
  width: 100%; 
  height: 400px; 
  align-items: center;
    justify-content: center;
}

.detail-card-left img{
  width: 100%;
}
.detail-card-right {
  margin: 10px;
  padding: 10px;
  width: 100%;
}

 /* Media query for mobile */
 @media (max-width: 770px) {
  .home-blog-area{

  }
  .testimonial-section{
    padding: 5px;
  }
  .section-padding{
    margin: 0;
    padding: 0;
  }
  .category-section{
    padding: 5px;
  }
  .detail-content{
    padding-left: 5px;
    padding-right: 5px ;
  }
  .detail-img{
   padding: 5px !important;
  }
  .fixed-sidebar{
    padding: 0;
  }
  .detail-card{
    flex-direction: column;
  }
  .detail-card-left{
    display: flex;
    width: 100%; 
    height: auto; 
  }
  .row-2{
    padding: 0;;
  }
  .category-section p{
    text-align: left;
    display: flex;
    flex-direction: column;
  }
}
.row-2{
display: flex;
flex-direction: row;
}
.price-detials{
  font-size: 26px !important;
  
}
.price-detials a{
  color: black !important;
}
.blog_details{
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 20px rgb(15 15 15 / 20%);
}

.blog_details ul li{
   display: flex;
   justify-content: left;
}
.fa-check-circle{
  padding: 10px;
  font-size: 25px;
}
.blog_details h2{
 display: flex;
 justify-content: left;
}
.fa-thumbs-up{
  color: #e6005c;
  font-size: 21px;
    padding: 10px;
}
.blog-info-link a{
  font-size: 17px;
}
.blog-cap-1{
  padding: 5px !important;
}
.container-1{
  margin-left: 5px;
  margin-right: 5px;
}