.testimonial-section {
    padding: 40px;
    background-color: #f7f7f7;
  }
  
  .testimonial-card {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(204, 187, 187, 0.1);
    height: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px; /* Gap between cards */
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .testimonial-card:hover {
    transform: translateY(-10px) scale(1.05); /* Smooth pop-up effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
  }
  
  .testimonial-card img.testimonial-img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
  }
  
  .testimonial-section .slick-slide {
    padding: 10px; /* Gap on the sides of each slide */
  }
  
  .slick-dots li button:before {
    color: #000;
  }
  
  .slick-dots {
    margin-top: 20px;
  }
  