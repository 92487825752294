.testimonial-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive grid layout */
    gap: 20px;
  }
  
  .testimonial-card-birthday {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: center;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .testimonial-card-birthday img {
    max-width: 100%;
    height: auto;

  }
  
  .testimonial-card-birthday h4 {
    margin: 10px 0;
  }
  
  .testimonial-card-birthday p {
    margin: 10px 0;
  }
  
  .testimonial-card-birthday:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for better visibility */
  }
  
  .testimonial-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns on larger screens */
    gap: 20px;
  }
  
  .testimonial-card-birthday {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: center;
  }
  
  /* Media query for tablets */
  @media (max-width: 1024px) {
    .testimonial-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on tablet screens */
    }
  }
  
  /* Media query for mobile */
  @media (max-width: 600px) {
    .testimonial-grid {
      grid-template-columns: repeat(1, 1fr); /* 1 column on mobile screens */
    }
  }
  .birthday-packages-section {
    text-align: center; /* Center the title and lines */
  }
  
  .section-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .section-title h2 {
    margin: 0 20px; /* Space between the title and lines */
  }
  
  .line {
    flex: 1; /* Make the lines extend to fill the available space */
    border-bottom: 1px solid #ddd; /* Style of the horizontal line */
  }
  