.faq-container {
    padding: 130px;
    background-color: #f9f9f9;
}

.faq-list {
    margin-top: 20px;
}

.faq-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.faq-question h4 {
    margin: 0;
    font-size: 18px;
}

.faq-toggle {
    font-size: 24px;
    font-weight: bold;
}

.faq-answer {
    margin-top: 10px;
    font-size: 16px;
}
@media (max-width: 771px){
    .faq-container {
        padding: 60px;
    
    }
  }
  
