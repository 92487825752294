.row-1{
    display: flex;
    flex-direction: row-reverse;
}
.btn_pck{
    color: #fff !important;
    background-color: #e6005c;
    padding:20px 30px ;
}
.btn_pck:hover{
    color: #e6005c;
    background-color: #fff;
    border: 1px solid #e6005c;
}
.btn_pck_date:hover{
   color: #e6005c;
   border: 1px solid #fff;
   background-color: #e6005c;
   cursor:pointer;
}