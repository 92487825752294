.navbar {
    margin-bottom: 0;
}
.row{
    margin: 10px;
    padding: 10px;
}
Link:hover{
  color : #e6005c;
}
.package{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    height: inherit;
    position: relative;
}
.socialmedia{
    width: 100%;
    height: 100%;
    margin: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contenttext{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.ulsocial{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
 
.fonticon{
    font-size: 1.5rem;
}
.adddver{
height: 80px;
    background-color: #DAF0EE;
    position: relative;
    top: -20px;
    margin-top: 0;
    padding-top: 0;
}
.paragraph{
    border: 1px solid;
    border-radius: 50px;
    padding: 10px;
}
.fonticona:hover,.paragraph:hover{
    border: none;
    background: #e6005c;
    color: #fffffff4;
}
 
.fonticona{
    border: 1px solid;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 5px;
    border-radius: 11px;
}
 
 
@media screen and (max-width: 400px) {
    .package,.adddver{
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .checkpackage{
        display: flex;
        width: 70%;
    }
    .socialmedia{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        
    }
     .fonticon{
       font-size: 1rem;
    }
    .contenttext{
        width: 100%;
    }
  }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent;
  }
  @media (min-width: 768px) {
    .navbar {
      border-radius: 4px;
    }
  }
  @media (min-width: 768px) {
    .navbar-header {
      float: left;
    }
  }
  .navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
  }
  .navbar-collapse.in {
    overflow-y: auto;
  }
  @media (min-width: 768px) {
    .navbar-collapse {
      width: auto;
      border-top: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    .navbar-collapse.collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0;
      overflow: visible !important;
    }
    .navbar-collapse.in {
      overflow-y: visible;
    }
    .navbar-fixed-top .navbar-collapse,
    .navbar-static-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: 340px;
  }
  @media (max-device-width: 480px) and (orientation: landscape) {
    .navbar-fixed-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
      max-height: 200px;
    }
  }
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
  }
  @media (min-width: 768px) {
    .container > .navbar-header,
    .container-fluid > .navbar-header,
    .container > .navbar-collapse,
    .container-fluid > .navbar-collapse {
      margin-right: 0;
      margin-left: 0;
    }
  }
  .navbar-static-top {
    z-index: 1000;
    border-width: 0 0 1px;
  }
  @media (min-width: 768px) {
    .navbar-static-top {
      border-radius: 0;
    }
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  @media (min-width: 768px) {
    .navbar-fixed-top,
    .navbar-fixed-bottom {
      border-radius: 0;
    }
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-fixed-bottom {
    bottom: 0;
    margin-bottom: 0;
    border-width: 1px 0 0;
  }
  .navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
  }
  .navbar-brand:hover,
  .navbar-brand:focus {
    text-decoration: none;
  }
  .navbar-brand > img {
    display: block;
  }
  @media (min-width: 768px) {
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
      margin-left: -15px;
    }
  }
  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .navbar-toggle:focus {
    outline: 0;
  }
  .navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
  }
  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 4px;
  }
  @media (min-width: 768px) {
    .navbar-toggle {
      display: none;
    }
  }
  .navbar-nav {
    margin: 7.5px -15px;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
  }
  @media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    .navbar-nav .open .dropdown-menu > li > a,
    .navbar-nav .open .dropdown-menu .dropdown-header {
      padding: 5px 15px 5px 25px;
    }
    .navbar-nav .open .dropdown-menu > li > a {
      line-height: 20px;
    }
    .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-nav .open .dropdown-menu > li > a:focus {
      background-image: none;
    }
  }
  @media (min-width: 768px) {
    .navbar-nav {
      float: left;
      margin: 0;
    }
    .navbar-nav > li {
      float: left;
    }
    .navbar-nav > li > a {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .navbar-form {
    padding: 10px 15px;
    margin-top: 8px;
    margin-right: -15px;
    margin-bottom: 8px;
    margin-left: -15px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
            box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
  }
  @media (min-width: 768px) {
    .navbar-form .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .navbar-form .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .navbar-form .form-control-static {
      display: inline-block;
    }
    .navbar-form .input-group {
      display: inline-table;
      vertical-align: middle;
    }
    .navbar-form .input-group .input-group-addon,
    .navbar-form .input-group .input-group-btn,
    .navbar-form .input-group .form-control {
      width: auto;
    }
    .navbar-form .input-group > .form-control {
      width: 100%;
    }
    .navbar-form .control-label {
      margin-bottom: 0;
      vertical-align: middle;
    }
    .navbar-form .radio,
    .navbar-form .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .navbar-form .radio label,
    .navbar-form .checkbox label {
      padding-left: 0;
    }
    .navbar-form .radio input[type="radio"],
    .navbar-form .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0;
    }
    .navbar-form .has-feedback .form-control-feedback {
      top: 0;
    }
  }
  @media (max-width: 767px) {
    .navbar-form .form-group {
      margin-bottom: 5px;
    }
    .navbar-form .form-group:last-child {
      margin-bottom: 0;
    }
  }
  @media (min-width: 768px) {
    .navbar-form {
      width: auto;
      padding-top: 0;
      padding-bottom: 0;
      margin-right: 0;
      margin-left: 0;
      border: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
  }
  .navbar-nav > li > .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-btn {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .navbar-btn.btn-sm {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .navbar-btn.btn-xs {
    margin-top: 14px;
    margin-bottom: 14px;
  }
  .navbar-text {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media (min-width: 768px) {
    .navbar-text {
      float: left;
      margin-right: 15px;
      margin-left: 15px;
    }
  }
  @media (min-width: 768px) {
    .navbar-left {
      float: left !important;
    }
    
    .navbar-right ~ .navbar-right {
      margin-right: 0;
    }
  }
  .navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
  }
  .navbar-default .navbar-brand {
    color: #777;
  }
  .navbar-default .navbar-brand:hover,
  .navbar-default .navbar-brand:focus {
    color: #5e5e5e;
    background-color: transparent;
  }
  .navbar-default .navbar-text {
    color: #777;
  }
  .navbar-default .navbar-nav > li > a {
    color: #777;
  }
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus {
    color: #333;
    background-color: transparent;
  }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
    color: #555;
    background-color: #e7e7e7;
  }
  .navbar-default .navbar-nav > .disabled > a,
  .navbar-default .navbar-nav > .disabled > a:hover,
  .navbar-default .navbar-nav > .disabled > a:focus {
    color: #ccc;
    background-color: transparent;
  }
  .navbar-default .navbar-toggle {
    border-color: #ddd;
  }
  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background-color: #ddd;
  }
  .navbar-default .navbar-toggle .icon-bar {
    background-color: #888;
  }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border-color: #e7e7e7;
  }
  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:hover,
  .navbar-default .navbar-nav > .open > a:focus {
    color: #555;
    background-color: #e7e7e7;
  }
  @media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
      color: #777;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
      color: #333;
      background-color: transparent;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
      color: #555;
      background-color: #e7e7e7;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
      color: #ccc;
      background-color: transparent;
    }
  }
  .navbar-default .navbar-link {
    color: #777;
  }
  .navbar-default .navbar-link:hover {
    color: #333;
  }
  .navbar-default .btn-link {
    color: #777;
  }
  .navbar-default .btn-link:hover,
  .navbar-default .btn-link:focus {
    color: #333;
  }
  .navbar-default .btn-link[disabled]:hover,
  fieldset[disabled] .navbar-default .btn-link:hover,
  .navbar-default .btn-link[disabled]:focus,
  fieldset[disabled] .navbar-default .btn-link:focus {
    color: #ccc;
  }
  .navbar-inverse {
    background-color: #222;
    border-color: #080808;
  }
  .navbar-inverse .navbar-brand {
    color: #9d9d9d;
  }
  .navbar-inverse .navbar-brand:hover,
  .navbar-inverse .navbar-brand:focus {
    color: #fff;
    background-color: transparent;
  }
  .navbar-inverse .navbar-text {
    color: #9d9d9d;
  }
  .navbar-inverse .navbar-nav > li > a {
    color: #9d9d9d;
  }
  .navbar-inverse .navbar-nav > li > a:hover,
  .navbar-inverse .navbar-nav > li > a:focus {
    color: #fff;
    background-color: transparent;
  }
  .navbar-inverse .navbar-nav > .active > a,
  .navbar-inverse .navbar-nav > .active > a:hover,
  .navbar-inverse .navbar-nav > .active > a:focus {
    color: #fff;
    background-color: #080808;
  }
  .navbar-inverse .navbar-nav > .disabled > a,
  .navbar-inverse .navbar-nav > .disabled > a:hover,
  .navbar-inverse .navbar-nav > .disabled > a:focus {
    color: #444;
    background-color: transparent;
  }
  .navbar-inverse .navbar-toggle {
    border-color: #333;
  }
  .navbar-inverse .navbar-toggle:hover,
  .navbar-inverse .navbar-toggle:focus {
    background-color: #333;
  }
  .navbar-inverse .navbar-toggle .icon-bar {
    background-color: #fff;
  }
  .navbar-inverse .navbar-collapse,
  .navbar-inverse .navbar-form {
    border-color: #101010;
  }
  .navbar-inverse .navbar-nav > .open > a,
  .navbar-inverse .navbar-nav > .open > a:hover,
  .navbar-inverse .navbar-nav > .open > a:focus {
    color: #fff;
    background-color: #080808;
  }
  @media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
      border-color: #080808;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
      background-color: #080808;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
      color: #9d9d9d;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
      color: #fff;
      background-color: transparent;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
      color: #fff;
      background-color: #080808;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
      color: #444;
      background-color: transparent;
    }
  }
  .navbar-inverse .navbar-link {
    color: #9d9d9d;
  }
  .navbar-inverse .navbar-link:hover {
    color: #fff;
  }
  .navbar-inverse .btn-link {
    color: #9d9d9d;
  }
  .navbar-inverse .btn-link:hover,
  .navbar-inverse .btn-link:focus {
    color: #fff;
  }
  .navbar-inverse .btn-link[disabled]:hover,
  fieldset[disabled] .navbar-inverse .btn-link:hover,
  .navbar-inverse .btn-link[disabled]:focus,
  fieldset[disabled] .navbar-inverse .btn-link:focus {
    color: #444;
  }
/* Navigation */
#menu {
    padding: 15px;
    transition: all 0.8s;
    border-bottom: 3px solid #e6005c;
  }
  #menu.navbar-default {
    background-color: #fff;
  }
  #menu a.navbar-brand {
    font-family: "Raleway", sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
  }
  #menu.navbar-default .navbar-nav > li > a {
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    color: #555;
    font-size: 15px;
    font-weight: 400;
    padding: 8px 2px;
    border-radius: 0;
    margin: 9px 20px 0;
  }
  #menu.navbar-default .navbar-nav > li > a:after {
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 2px;
    background: linear-gradient(to right, #e6005c 0%, #e6005c 100%);
    content: "";
    transition: width 0.2s;
  }
  #menu.navbar-default .navbar-nav > li > a:hover:after {
    width: 100%;
  }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent;
  }
  .navbar-default .navbar-nav > .active > a:after,
  .navbar-default .navbar-nav > .active > a:hover:after,
  .navbar-default .navbar-nav > .active > a:focus:after {
    display: block !important;
    position: absolute !important;
    left: 0 !important;
    bottom: -1px !important;
    width: 100% !important;
    height: 2px !important;
    background: linear-gradient(to right, #e6005c 0%, #e6005c 100%) !important;
    content: "" !important;
    transition: width 0.2s !important;
  }
  .navbar-toggle {
    border-radius: 0;
  }
  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background-color: #fff;
    border-color: #e6005c;
  }
  .navbar-default .navbar-toggle:hover > .icon-bar {
    background-color: #e6005c;
  }
  @media (min-width: 768px) {
    .navbar-header {
      float: left;
    }
  }
  .container > .navbar-header,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 768px) {
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after
 {
  display: table;
  content: " ";
}
li{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar-header:after,
.navbar-collapse:after
.container:after,
.container-fluid:after {
  clear: both;
}
.navbar-right{
    display: flex;
    flex-direction: column;
    height: 50px;
    justify-content: center;
    align-items: center;
}
.submenu {
  display: none;
  position: absolute;
  top: 50px;
  background-color: #f9f9f9; /* Adjust the background as needed */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Optional shadow */
  z-index: 1;
  min-width: 160px;
}

.navbar-nav > li {
  position: relative;
}

.navbar-nav > li:hover .submenu {
  display: block;
}

.submenu li {
  padding: 8px 16px;
}

.submenu li a {
  color: #000; /* Adjust link color as needed */
  text-decoration: none;
  display: block;
}
.navbar>.container{
  display: flex;
  flex-direction: row;
}
.submenu li a:hover {
  background-color: #ddd; /* Optional hover effect */
}
@media (max-width: 771px) {
  .navbar>.container{
    display: flex;
    flex-direction: column;
  }
  .navbar-right {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
  }
 
  .navbar-sandwich{
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
.submenu {
  display: none;
}

.submenu.open {
  display: block;
}

li {
  list-style: none;
}

.page-scroll {
  cursor: pointer;
}
