.gutter1{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}
.team-social li a{
    color: #e6005c;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 20px;
}
.whatsapp-icon-1,.phone-icon-1{
    margin-top: 15px;
}
@media (max-width: 767px) {
    .whatsapp-icon-1,.phone-icon-1 {
        margin-top: 15px;
    }
}
.bttn{
    padding: 20px 40px;
}
.phone-icon-3{
    margin: 0;
}


.funimg{
    width: 700px;
}
@media (max-width: 700px) {

    .funimg{
        width: 100%;
    }
}